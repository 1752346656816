import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PerfumeContext } from "../components/perfumescontext";
import Perfume from "../components/perfume";

const combinationMapping = new Map([
  ["masculine_Fresh_day", 1],
  ["masculine_Fresh_evening", 2],
  ["masculine_Florals_day", 3],
  ["masculine_Florals_evening", 4],
  ["masculine_Sensuals_day", 5],
  ["masculine_Sensuals_evening", 6],
  ["masculine_Woods_day", 7],
  ["masculine_Woods_evening", 8],
  ["masculine_Orientals_day", 9],
  ["masculine_Orientals_evening", 10],
  ["feminine_Fresh_day", 11],
  ["feminine_Fresh_evening", 2],
  ["feminine_Florals_day", 3],
  ["feminine_Florals_evening", 4],
  ["feminine_Sensuals_day", 12],
  ["feminine_Sensuals_evening", 13],
  ["feminine_Woods_day", 7],
  ["feminine_Woods_evening", 14],
  ["feminine_Orientals_day", 9],
  ["feminine_Orientals_evening", 15],
]);

const Results = () => {
  const { data, sets } = useContext(PerfumeContext);
  const [filteredPerfumes, setFilteredPerfumes] = useState([]);
  const location = useLocation();
  const { sex, family, dayTime } = location.state || {};

  const combination = `${sex}_${family.title}_${dayTime}`;
  const setNumber = combinationMapping.get(combination);

  useEffect(() => {
    //Obtenir els perfums dels sets i buscarlos al data per lo seu id
    console.log(combination, setNumber);
    const perfumes = sets.filter((set) => {
      return set.number === setNumber;
    });
    console.log(perfumes);
    if (perfumes.length != 0) {
      setFilteredPerfumes(
        data.filter((perfume) => {
          return perfumes[0]?.perfumes.includes(perfume._id);
        })
      );
    }
    console.log(filteredPerfumes);
  }, []);

  return (
    <div className="App bg-two">
      <div className="container">
        <div className="row d-flex h-100">
          <div className="col text-center align-self-center">
            <div className="container">
              <div className="row">
                <div
                  className="col-12 f2 mb-5 pb-5"
                  style={{ fontFamily: "classgaramond", letterSpacing: "5px" }}
                >
                  We think you will love
                  <br />
                  these <span className="italic">three fragances</span>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                  }}
                >
                  {filteredPerfumes.map((perfume, index) => (
                    <Perfume
                      key={index}
                      title={perfume.family}
                      name={perfume.name}
                      img={perfume.imgURLS}
                      description={perfume.description}
                      ingredients={perfume.ingredients}
                      t={perfume.T}
                      h={perfume.H}
                      b={perfume.B}
                      ean={perfume.ean}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
