import React, { useState } from 'react';
import { SearchContext } from './searchcontext';

function SearchProvider({ children }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  return (
    <SearchContext.Provider value={{ searchQuery, searchResults, setSearchQuery, setSearchResults }}>
      {children}
    </SearchContext.Provider>
  );
}

export default SearchProvider;
