import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import top from "../assets/top.svg";
import heart from "../assets/heart.svg";
import base from "../assets/base.svg";
import modernrose from "../assets/modern-rose.svg";
import { LanguageContext } from "../components/languagecontext";

const Discover = () => {
  const location = useLocation();
  const { title, name, img, description, ingredients, t, h, b } =
    location.state || {};
  const { language, setLanguage } = useContext(LanguageContext);
  console.log(description[0].en, t, h, b);
  return (
    <div className="App bg-two">
      <div className="container">
        <div className="row d-flex h-100">
          <div className="col align-self-center">
            <div className="f1 letterSpacing">The {title.title}</div>
            <h1 className="position-relative">
              <div className="h1discover">{name}</div>
              <div className="photoDiscover">
                <img src={modernrose} />
              </div>
            </h1>
            <p className="f1 w-50">
              {language === "English" ? description[0].en : description[1].nl}
            </p>
            <div className="recuadrohor d-flex justify-content-around w-50 my-5 row mx-0">
              <div className="col-2">
                <img src={top} className="mnegprod" />
              </div>
              <div className="col-10">
                <div className="f4 italic my-2">Top</div>
                <div className="my-2">{t}</div>
              </div>
            </div>
            <div className="recuadrohor d-flex justify-content-around w-50 my-5 row mx-0">
              <div className="col-2">
                <img src={heart} className="mnegprod" />
              </div>
              <div className="col-10">
                <div className="f4 italic my-2">Heart</div>
                <div className="my-2">{h}</div>
              </div>
            </div>
            <div className="recuadrohor d-flex justify-content-around w-50 my-5 row mx-0">
              <div className="col-2">
                <img src={base} className="mnegprod" />
              </div>
              <div className="col-10">
                <div className="f4 italic my-2">Base</div>
                <div className="my-2">{b}</div>
              </div>
            </div>
            <Link to="/">
              <button>BACK TO START</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;
