import { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "./searchcontext";
import search from "../assets/ico-lupa.svg";

function SearchBar({ isOpen }) {
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery, setSearchResults } =
    useContext(SearchContext);
  const searchBarRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClickOutside = (event) => {
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      // setSearchQuery('');
      // toggleOpen();
      // navigate(-1);
      //MIRAR SI AMAGO O QUE FAIG AMB LA BARRA
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOpen = () => {
    setOpen(!open);
    isOpen(open);
  };

  return (
    <>
      <div
        ref={searchBarRef}
        style={{
          display: "flex",
          alignSelf: "center",
          width: "150%",
          backgroundColor: "transparent",
          outline: "none",
          border: "none",
          boxShadow: "none",
        }}
      >
        <img
          src={search}
          alt="search"
          style={{ cursor: "pointer", marginRight: "1.5rem", color: "white" }}
        />
        <input
          type="text"
          placeholder="Search on Name, ingredient or family"
          value={searchQuery}
          onChange={handleChange}
          style={{
            border: "none",
            backgroundColor: "transparent",
            fontFamily: "scalasans",
            color: "white",
            width: "60%",
            caretColor: "#00C2FF",
            outline: "none",
            boxShadow: "none",
          }}
        />
      </div>
    </>
  );
}

export default SearchBar;
