// ------------WEBSOCKET IMPLEMENTATION------------------------

//default address
const WS = "http://192.168.46.1:3000";
// HTTPS address
//const WS = 'https://airparfum-machine.com:3001';
// address to be used when testing with the Simulator APP
// const WS = 'http://airparfum-machine:3000';
const USER = "appuser";
const PASSWORD = "FrontApp#Pass&";
const EVENT = "message";

// Socket Errors
const socketErrors = {
  1: "ERROR_PARAMETERS",
  2: "PERFUME_DISABLED",
  3: "ERROR_EXECUTION",
  5: "API_DISABLED",
  6: "EXE_MAXTIMEREACHED",
  //comment this if you don't want to consider innactivity as an exception
  //7: "WAIT_MAXTIMEREACHED",
  8: "ERROR_CONNECTION",
  9: "SPRAY_DISABLEDBYMACHINE",
  10: "MAINTENANCE_MODE",
};

const io = require("socket.io-client");

const webSocket = io(WS);
let connectionError = true;
let errorResponse = false;

// Capture all events
let onevent = webSocket.onevent;
webSocket.onevent = function (packet) {
  connectionError = false;
  let args = packet.data || [];
  onevent.call(this, packet); // original call
  packet.data = ["*"].concat(args);
  onevent.call(this, packet); // additional call to catch-all
};

webSocket.on("connect_error", (error) => {
  console.log("Connection error", error);
  connectionError = true;
});

// Detect responses from machine
webSocket.on("*", (event, data) => {
  connectionError = false;
  const show =
    data.response &&
    Object.keys(socketErrors).indexOf(data.response.toString()) !== -1;
  showError(show, show ? data.comment : "");
});

// Show error messages
function showError(show, message) {
  if (show) console.log("error", message);
  errorResponse = show ? true : false;
}

// Send data to machine
function sendData(typeOp, extraParameters = {}) {
  if (!typeOp) {
    return;
  }
  let data = {
    user: USER,
    password: PASSWORD,
    typeOp: typeOp,
  };

  if (Object.keys(extraParameters).length > 0) {
    data.parameters = extraParameters;
  }
  console.log("ENtro");
  webSocket.emit(EVENT, data, function (response) {
    console.log(response);
  });
}

//start sprayed perfume
export async function startPerfume(ean, testMode = 0) {
  const parameters = {
    EAN: ean,
    group: 0,
    power: 0, // Default power (12)
    testMode: testMode, // Activate by proximity sensor if 0
  };
  sendData("startPerfume", parameters);
  console.log("Now you can smell perfume: ", ean);
}
//stop sprayed perfume
function stopPerfume(ean) {
  sendData("stopPerfume");
  console.log("Perfume stopped", ean);
}

export async function startMixPerfumeGroup(
  ean1,
  percPerfume1,
  group1,
  ean2,
  percPerfume2,
  group2
) {
  console.log("Now you can smell mix perfume: " + ean1 + " - " + ean2);
  const parameters = {
    list: [
      { EAN: ean1, percMix: percPerfume1, group: group1 },
      { EAN: ean2, percMix: percPerfume2, group: group2 },
    ],
    power: 5, // Default power (12)
    testMode: 5, // Activate by proximity sensor
  };
  sendData("startPerfumeMixGroup", parameters);
}
/*
• User session
For the start/end session events, we need the following API's to be called:
*/
export function initSession() {
  const date = new Date();
  console.log("INIT SESSION:" + date);
  sendData("initSession");
}

function endSession() {
  const date = new Date();
  console.log("END SESSION:" + date);
  sendData("endSession");
}

// ------------------------------------
