import { Link } from "react-router-dom";
import { initSession } from "../socket";

const Family = ({ title, img, name, description, sex }) => {
  const nameParts = name.split(" ");
  const firstPart = nameParts[0];
  const secondPart = nameParts.slice(1).join(" ");
  const [firstDescriptionPart, secondDescriptionPart] =
    description.split(" & ");
  const handleConnection = async () => {
    initSession();
    // startPerfume("0000000000005", 5).then((r) => console.log(r));
  };

  return (
    <div
      className="recuadro-2 recuadro-gradient p-3 mx-1"
      style={{ width: "300px" }}
    >
      <div className="f1 letterSpacing">THE</div>
      <div className="f1 letterSpacing">{title.title.toUpperCase()}</div>
      <div className="my-3">
        <img src={img} alt="Family" />
      </div>
      <div className="f1" style={{ fontFamily: "classgaramondBT" }}>
        <span style={{ fontFamily: "classgaramondBold" }}>{firstPart}</span>
        <br />
        <span style={{ fontFamily: "classgaramondItalic" }}>{secondPart}</span>
      </div>
      <div className="mt-3" style={{ fontFamily: "scalasans" }}>
        {firstDescriptionPart}
      </div>
      <div style={{ fontFamily: "scalasans" }}>& {secondDescriptionPart}</div>
      <div className="containerButton">
        {/* PASSAR CORRECTAMENT LES PROPS PER TAL QUE FUNCIONI EL PERFUM QUE VOLEM EANCODE*/}
        <button className="button-2" onClick={handleConnection}>
          Try It
        </button>
        <Link
          to={"/when-do-you-want"}
          state={{
            family: title,
            sex: sex,
          }}
          style={{ textDecoration: "none" }}
        >
          <button
            className="my-3 mx-2 button-2"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              CHOOSE {title.title.toUpperCase()}
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Family;
