import React, { useContext, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { SearchContext } from "../components/searchcontext";
import { PerfumeContext } from "../components/perfumescontext";

import Perfume from "../components/perfume";

const suggestedSearches = [
  { term: "Cardomom", results: 6 },
  { term: "Gracious Lily", results: 1 },
  { term: "The Woods", results: 5 },
];

const SearchPerfum = () => {
  const { searchQuery } = useContext(SearchContext);
  const [perfumes, setPerfumes] = useState([]);
  const [filteredPerfumes, setFilteredPerfumes] = useState([]);
  const { data } = useContext(PerfumeContext);
  useEffect(() => {
    setPerfumes(data);
    setFilteredPerfumes(
      data.sort((a, b) => {
        const order = ["Fresh", "Florals", "Sensuals", "Woods", "Orientals"];
        return order.indexOf(a.family.title) - order.indexOf(b.family.title);
      })
    );
  }, []);

  useEffect(() => {
    setFilteredPerfumes(
      perfumes.filter((perfume) =>
        perfume.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    //AFEGIR FILTRATGE PER NOM, INGREDIENT O FAMILIA
  }, [searchQuery, perfumes]);

  const searchPerfumStyle = useSpring({
    opacity: 1,
    from: { opacity: 0.5 },
    config: { duration: 700 },
  });

  return (
    <>
      <animated.div className="App bg-two" style={{ ...searchPerfumStyle }}>
        {/* <div className="App bg-two">  */}
        <div className="container">
          <div className="row d-flex h-100">
            <div className="col text-center align-self-center">
              {filteredPerfumes?.length > 0 ? (
                <>
                  <div className="container" style={{ marginTop: "80px" }}>
                    <span
                      className="d-flex flex-start"
                      style={{ fontFamily: "scalasans" }}
                    >
                      {filteredPerfumes?.length} Perfumes
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, 1fr)",
                      }}
                    >
                      {filteredPerfumes.map((perfume, index) => (
                        <Perfume
                          key={index}
                          title={perfume.family}
                          name={perfume.name}
                          img={perfume.imgURLS}
                          description={perfume.description}
                          ingredients={perfume.ingredients}
                          t={perfume.T}
                          h={perfume.H}
                          b={perfume.B}
                          ean={perfume.ean}
                        />
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h3 className="italic">
                    Unfortunately, we do not have a Talisman <br /> that matches
                    your search
                  </h3>
                  <br />
                  <span>Try the following:</span>
                  {suggestedSearches.map((suggestion, index) => (
                    <>
                      <div
                        key={index}
                        className="d-flex justify-content-between"
                      >
                        <div>{suggestion.term}</div>
                        <div>{suggestion.results} results</div>
                      </div>
                      {index !== suggestedSearches.length - 1 && (
                        <hr className="w-100" />
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </animated.div>
    </>
  );
};

export default SearchPerfum;
