import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Home from "./pages/home";
import Selectway from "./pages/selectway";
import Mascorfem from "./pages/mascorfem";
import Whichfragance from "./pages/whichfragance";
import Whendoyouwant from "./pages/whendoyouwant";
import Results from "./pages/results";
import Discover from "./pages/discover";
import SearchPerfum from "./pages/searchperfum";
import SearchProvider from "./components/searchprovider";
import { LanguageProvider } from "./components/languagecontext";
import SearchDropDown from "./pages/search-dropdown";
import { PerfumeProvider } from "./components/perfumescontext";

function App() {
  return (
    <Router>
      <LanguageProvider>
        <PerfumeProvider>
          <SearchProvider>
            <Navbar />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/search" element={<SearchPerfum />} />
              <Route path="/search-dropdown" element={<SearchDropDown />} />
              <Route path="/select-way" element={<Selectway />} />
              <Route path="/masculine-or-feminine" element={<Mascorfem />} />
              <Route path="/which-fragance" element={<Whichfragance />} />
              <Route path="/when-do-you-want" element={<Whendoyouwant />} />
              <Route path="/results" element={<Results />} />
              <Route path="/discover" element={<Discover />} />
            </Routes>
          </SearchProvider>
        </PerfumeProvider>
      </LanguageProvider>
    </Router>
  );
}

export default App;
