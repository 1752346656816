import React, { useState, useEffect, useContext } from "react";
import back from "../assets/back.svg";
import search from "../assets/search.svg";
import info from "../assets/points.svg";
import cross from "../assets/cross.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import SearchBar from "./searchBar";
import { LanguageContext } from "./languagecontext";

const Navbar = () => {
  const navigate = useNavigate();
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [unfurling, setUnfurling] = useState(false);
  const [languageDropDown, setLanguageDropDown] = useState(false);
  const location = useLocation();
  const currentComponent = location.pathname;
  const showSearchBar = currentComponent === "/search";
  const { language, setLanguage } = useContext(LanguageContext);

  const toggleDropdown = () => {
    setLanguageDropDown(!languageDropDown);
    console.log(languageDropDown);
  };

  const handleGoBack = () => {
    setIsSearchBarVisible(false);
    navigate(-1);
  };

  const handleLanguage = (lng) => {
    setLanguage(lng);
  };

  const handleUnfurling = () => {
    setUnfurling(!unfurling);
  };

  const goToPerfumesPage = () => {
    navigate("/search");
    setIsSearchBarVisible(true);
  };

  const searchBarStyle = useSpring({
    transform: isSearchBarVisible && showSearchBar ? "scaleX(1)" : "scaleX(0)",
    transformOrigin: "right",
    backgroundColor: "rgba(255, 255, 255, 0)",
    config: { duration: 400 },
  });

  useEffect(() => {
    const handleScroll = () => {
      setIsSearchBarVisible(window.scrollY === 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand" aria-label="Second navbar example">
        <div
          className="container-fluid"
          style={
            isSearchBarVisible
              ? { border: "none", backgroundColor: "transparent" }
              : {}
          }
        >
          <a
            className="navbar-brand"
            style={{ cursor: "pointer" }}
            onClick={handleGoBack}
          >
            <img src={back} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample02"
            aria-controls="navbarsExample02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <animated.div
            style={{
              ...searchBarStyle,
              position: "absolute",
              right: 0,
              width: "90%",
              height: "60%",
              display: "flex",
            }}
          >
            {isSearchBarVisible && <SearchBar isOpen={setIsSearchBarVisible} />}
          </animated.div>
          {!isSearchBarVisible && !unfurling && (
            <>
              <div
                className="collapse navbar-collapse mx-1"
                id="navbarsExample02"
              >
                <ul className="navbar-nav me-auto"></ul>
                <a onClick={goToPerfumesPage} style={{ cursor: "pointer" }}>
                  <img src={search} />
                </a>
              </div>
              <a
                className="mx-1"
                onClick={handleUnfurling}
                style={{ cursor: "pointer" }}
              >
                <img src={info} />
              </a>
            </>
          )}
          {unfurling && (
            <div className="d-flex">
              <a className="mx-1" style={{ cursor: "pointer" }}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/languageIcon.png`}
                  style={{ height: "40px", width: "40px" }}
                />
              </a>
              <div class="dropdown">
                <div
                  class="dropdown-toggle"
                  id="language-dropdown"
                  onClick={toggleDropdown}
                >
                  {language}
                </div>
                {languageDropDown && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      left: "20%",
                    }}
                  >
                    <a
                      onClick={() => handleLanguage("English")}
                      style={{ cursor: "pointer" }}
                    >
                      <span style={{ color: "white" }}>English</span>
                    </a>
                    <a
                      onClick={() => handleLanguage("Netherland")}
                      style={{ cursor: "pointer" }}
                    >
                      <span style={{ color: "white" }}>Netherlands</span>
                    </a>
                  </div>
                )}
              </div>
              <a
                className="mx-1"
                onClick={handleUnfurling}
                style={{ cursor: "pointer" }}
              >
                <img src={cross} />
              </a>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
