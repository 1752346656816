import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Whendoyouwant = () => {
  const location = useLocation();
  const { family, sex } = location.state || {};
  return (
    <div className="App bg-two">
      <div className="container">
        <div className="row d-flex h-100">
          <div className="col text-center align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-12 f2 pb-5">
                  When do you want to
                  <br />
                  <span className="italic">wear your perfume?</span>
                </div>
                <div className="d-flex justify-content-around">
                  <div className="recuadro-2 recuadro-daytime p-3 mx-2">
                    <div className="f4 mt90">
                      During the <br />
                      <span className="italic">Daytime</span>
                    </div>
                    <div className="containerButton">
                      <Link
                        to="/results"
                        state={{
                          sex: sex,
                          family: family,
                          dayTime: "day",
                        }}
                      >
                        <button className="my-4 button-2">
                          CHOOSE DAYTIME
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="recuadro-2 recuadro-evening p-3 mx-2">
                    <div className="f4 mt90">
                      During the <br />
                      <span className="italic">Evening</span>
                    </div>
                    <div className="containerButton">
                      <Link
                        to="/results"
                        state={{
                          sex: sex,
                          family: family,
                          dayTime: "evening",
                        }}
                      >
                        <button className="my-4 button-2">
                          CHOOSE EVENING
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whendoyouwant;
