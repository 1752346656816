import React from "react";
import icodiscover from "../assets/ico-discover.svg";
import icolupa from "../assets/ico-lupa.svg";
import { Link } from "react-router-dom";

const Selectway = () => {
  return (
    <div className="App bg-two">
      <div className="container">
        <div className="row d-flex h-100">
          <div className="col text-center recuadro align-self-center px-0 d-flex">
            <div className="align-self-center w-100">
              <img
                src={icodiscover}
                alt="Discover your own Talisman fragance"
                className="my-4"
              />
              <div
                className="f3 letterSpacing"
                style={{ fontFamily: "classgaramondBold" }}
              >
                IN 3 STEPS
              </div>
              <div className="f2 pb-5" style={{ fontFamily: "classgaramond" }}>
                Discover your own
                <br />
                <span style={{ fontFamily: "classgaramonditalic" }}>
                  Talisman fragrance
                </span>
              </div>
            </div>
            <div className="containerButton">
              <Link to="/masculine-or-feminine">
                <button className="my-4">START YOUR JOURNEY</button>
              </Link>
            </div>
          </div>
          <div className="col text-center recuadro align-self-center px-0 d-flex">
            <div className="align-self-center w-100">
              <img
                src={icolupa}
                alt="What is your favourite perfume?"
                className="my-4"
              />
              <div
                className="f3 letterSpacing"
                style={{ fontFamily: "classgaramondBold" }}
              >
                FIND YOUR MATCH
              </div>
              <div className="f2 pb-5" style={{ fontFamily: "classgaramond" }}>
                What is your
                <br />
                <span style={{ fontFamily: "classgaramonditalic" }}>
                  favourite perfume?
                </span>
              </div>  
            </div>
            <div className="containerButton">
              <Link to="/search-dropdown">
                <button className="my-4">FIND YOUR PERFUME</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selectway;
