import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { initSession, startPerfume, startMixPerfumeGroup } from "../socket";
import { LanguageContext } from "./languagecontext";

const Perfume = (props) => {
  const { title, name, description, ingredients, t, h, b, img, ean } =
    props || {};
  const { language, setLanguage } = useContext(LanguageContext);
  const location = useLocation();

  const handleConnection = async () => {
    initSession();
    startPerfume("000000" + ean, 5).then((r) => console.log(r));
    // startMixPerfumeGroup("0000000000005", 75, 1, "0000000000007", 25, 1);
  };
  const url = process.env.PUBLIC_URL + img;
  const getColorByTitle = (title) => {
    switch (title) {
      case "Fresh":
        return "#FCEFCA";
      case "Florals":
        return "#F4D7E5";
      case "Sensuals":
        return "#9F9489";
      case "Woods":
        return "#755729";
      case "Orientals":
        return "#242424";
      default:
        return "#FCEFCA";
    }
  };

  const getTitleColor = (title) => {
    switch (title) {
      case "Woods":
        return "#FFFFFF";
      case "Orientals":
        return "#FFFFFF";
      default:
        return "#000000";
    }
  };

  const backColor = getColorByTitle(title.title);
  const titleColor = getTitleColor(title.title);
  const nameParts = name.split(" ");
  const firstPart = nameParts[0];
  const secondPart = nameParts.slice(1).join(" ");

  return (
    <div className="container mt-5 pt-5 mb-5">
      <div className="d-flex mt-5">
        <div
          style={{
            marginTop: "-7%",
            marginLeft: location.pathname === "/search" ? "3%" : "6%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
          }}
        >
          <img src={url} alt="Perfume" className="image" />
        </div>
        <div className="recuadro p-5" style={{ flexGrow: 1 }}>
          <div
            className="f3 letterSpacing mt-5 px-2"
            style={{
              backgroundColor: backColor,
              color: titleColor,
              padding: "5px",
              fontFamily: "classgaramondBold",
              whiteSpace: "nowrap",
            }}
          >
            THE {title.title.toUpperCase()}
          </div>
          <div className="f2 mt-3">
            <span style={{ fontFamily: "classgaramondBold" }}>{firstPart}</span>
            <br />
            <span style={{ fontFamily: "classgaramondItalic" }}>
              {secondPart}
            </span>
          </div>
          {ingredients.map((ingredient, index) => (
            <span id={index} style={{ fontFamily: "scalasans" }}>
              {ingredient.name}
              <br></br>
            </span>
          ))}
          <div className="containerButton">
            <button className="button-1" onClick={handleConnection}>
              Try It
            </button>
            <br></br>
            <Link
              to="/discover"
              state={{
                title: title,
                name: name,
                img: url,
                description: description,
                ingredients: ingredients,
                t: t,
                h: h,
                b: b,
              }}
            >
              <button className="my-3 button-1">DISCOVER</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Perfume;
