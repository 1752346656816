import React from "react";
import { Link } from "react-router-dom";
import icon from "../assets/icon.svg";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  return (
    <div className="App bg-home">
      <div className="container">
        <div className="row d-flex h-100">
          <div className="col text-center ellipse py-5 align-self-center">
            <img src={icon} alt="House of rituals" className="my-4" />
            <div className="f1 letterSpacing">HOUSE OF RITUALS</div>
            <h1>
              The <span className="italic">Talisman</span>
              <br />
              Collection
            </h1>
            <div className="f2 mt-3">
              Create your own
              <br />
              <span className="italic">bespoke perfume</span>
            </div>
            <Link to="/select-way">
              <button className="my-4">START</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
