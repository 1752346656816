import React, { createContext, useState, useEffect } from "react";
import { getPerfumes } from "../api/perfume";
import { useLocation } from "react-router-dom";
import { getSets } from "../api/set";

const PerfumeContext = createContext();

const PerfumeProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [sets, setSets] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // if (location.pathname === "/") {
    fetchData();
    // }
  }, [location.pathname]);

  const fetchData = async () => {
    try {
      const response = await getPerfumes();
      const res = await getSets();
      setData(response.data);
      setSets(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PerfumeContext.Provider value={{ data, sets }}>
      {children}
    </PerfumeContext.Provider>
  );
};

export { PerfumeContext, PerfumeProvider };
