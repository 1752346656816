import React from "react";
import icodiscover from "../assets/ico-discover.svg";
import icolupa from "../assets/ico-lupa.svg";
import { Link } from "react-router-dom";

const Mascorfem = () => {
  return (
    <div className="App bg-two">
      <div className="container">
        <div className="row d-flex h-100">
          <div className="col text-center align-self-center">
            <div className="container">
              <div className="row">
                <div
                  className="col-12 f2 pb-5"
                  style={{ fontFamily: "classgaramond" }}
                >
                  Most of our fragrances are unisex,
                  <br />
                  <span className="italic">
                    but do you prefer scents with...
                  </span>
                </div>
                <div className="col recuadro recuadro-masculine px-0 d-flex">
                  <div className="align-self-center w-100">
                    <div
                      className="f2 pb-5"
                      style={{ fontFamily: "classgaramond" }}
                    >
                      Masculine
                      <br />
                      <span style={{ fontFamily: "classgaramondItalic" }}>
                        touches
                      </span>
                    </div>
                  </div>
                  <div className="containerButton">
                    <Link
                      to="/which-fragance"
                      state={{
                        sex: "masculine",
                      }}
                    >
                      <button className="my-4">I PREFER MASCULINE</button>
                    </Link>
                  </div>
                </div>
                <div className="col recuadro recuadro-feminine px-0 d-flex">
                  <div className="align-self-center w-100">
                    <div
                      className="f2 pb-5"
                      style={{ fontFamily: "classgaramond" }}
                    >
                      Feminine
                      <br />
                      <span style={{ fontFamily: "classgaramondItalic" }}>
                        touches
                      </span>
                    </div>
                  </div>
                  <div className="containerButton">
                    <Link
                      to="/which-fragance"
                      state={{
                        sex: "feminine",
                      }}
                    >
                      <button className="my-4">I PREFER FEMININE</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mascorfem;
