import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import search from "../assets/ico-lupa.svg";
import { SearchContext } from "../components/searchcontext";
import { PerfumeContext } from "../components/perfumescontext";

import { getPerfumes } from "../api/perfume";

const SearchDropDown = () => {
  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  const [perfumes, setPerfumes] = useState([]);
  const [filteredPerfumes, setFilteredPerfumes] = useState(perfumes);
  const [isSearching, setIsSearching] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { data } = useContext(PerfumeContext);

  useEffect(() => {
    setPerfumes(data);
    setFilteredPerfumes(data);
  }, [data]);

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    setIsSearching(event.target.value.length > 0);
  };

  const toggleDropdown = () => {
    setTimeout(() => {
      setIsDropdownOpen(!isDropdownOpen);
    }, 100);
  };

  useEffect(() => {
    setFilteredPerfumes(
      perfumes.filter((perfume) =>
        perfume.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);

  const searchPerfumStyle = useSpring({
    opacity: 1,
    from: { opacity: 0.5 },
    config: { duration: 700 },
  });

  const itemHeight = 50;
  const numItems = filteredPerfumes.length;
  const totalHeight = `${itemHeight * numItems}px`;

  const dropdownAnimation = useSpring({
    height: isDropdownOpen ? totalHeight : "0px",
    opacity: isDropdownOpen ? 1 : 0,
    from: { height: "0px", opacity: 0 },
    config: { duration: 1000 },
  });

  return (
    <>
      <animated.div className="App bg-two" style={{ ...searchPerfumStyle }}>
        {/* <div className="App bg-two">  */}
        <div className="container">
          <div className="row d-flex h-50">
            <div className="col text-center align-self-center d-flex flex-column">
              <h3 style={{ fontFamily: "classgaramondBold" }}>What is your</h3>
              <h3
                style={{
                  fontFamily: "classgaramondItalic",
                  marginBottom: "3rem",
                }}
              >
                favourite perfume?
              </h3>
              <div
                style={{
                  display: "flex",
                  alignSelf: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "45%",
                  maxHeight: "2rem",
                  marginTop: "1rem",
                  backgroundColor: "transparent",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <div
                  className="d-flex flex-direction-row text-center align-self-center justify-content-center mb-5"
                  style={{
                    marginBottom: "10px",
                    borderBottom: "1px solid white",
                    width: "100%",
                  }}
                >
                  <input
                    type="text"
                    placeholder="FIND YOUR FRAGANCE"
                    value={searchQuery}
                    onChange={handleChange}
                    onFocus={toggleDropdown}
                    onBlur={toggleDropdown}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      color: "white",
                      width: "100%",
                      caretColor: "#00C2FF",
                      outline: "none",
                      boxShadow: "none",
                      fontFamily: "scalasans",
                    }}
                  />
                  {isSearching ? (
                    <div className="dot-elastic"></div>
                  ) : (
                    <img
                      src={search}
                      alt="icon"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignSelf: "center",
                        height: "15px",
                        width: "15px",
                      }}
                    />
                  )}
                </div>
                {isDropdownOpen && (
                  <animated.div
                    className="d-flex flex-column justify-content-center position-absolute"
                    style={{
                      ...dropdownAnimation,
                      maxHeight: "25rem",
                      overflowY:
                        filteredPerfumes.length > 5 ? "auto" : "visible",
                      top: "100%",
                      width: "100%",
                      padding: "0.5rem",
                    }}
                  >
                    {filteredPerfumes.map((perfume, index) => (
                      <React.Fragment key={index}>
                        <div
                          key={index}
                          className="d-flex flex-start"
                          style={{
                            backgroundColor: "transparent",
                            color: "white",
                          }}
                        >
                          <Link
                            to="/discover"
                            state={{
                              title: perfume.family,
                              name: perfume.name,
                              img: perfume?.img,
                              description: perfume.description,
                              ingredients: perfume.ingredients,
                              t: perfume.T,
                              h: perfume.H,
                              b: perfume.B,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <span
                              className="text-white"
                              style={{
                                fontFamily: "scalasans",
                              }}
                            >
                              {perfume.name}
                            </span>
                          </Link>
                        </div>
                        <hr style={{ borderColor: "grey", width: "100%" }} />
                      </React.Fragment>
                    ))}
                  </animated.div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </animated.div>
    </>
  );
};

export default SearchDropDown;
