import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { PerfumeContext } from "../components/perfumescontext";
import Family from "../components/family";

const Whichfragance = () => {
  const [families, setFamilies] = useState([]);
  const location = useLocation();
  const { sex } = location.state || {};
  const { data } = useContext(PerfumeContext);

  useEffect(() => {
    if (sex === "masculine") {
      setFamilies(
        data.filter((item) => {
          return (
            item.name === "White Tea" ||
            item.name === "Blush Jasmine" ||
            item.name === "Balsamic Cardamom" ||
            item.name === "Blond Santal" ||
            item.name === "Smoky Suede"
          );
        })
      );
    } else {
      setFamilies(
        data.filter((item) => {
          return (
            item.name === "Fresh Neroli" ||
            item.name === "Blush Jasmine" ||
            item.name === "Golden Amber" ||
            item.name === "Woody Musc" ||
            item.name === "Spicy Vanilla"
          );
        })
      );
    }
  }, []);

  const descriptions = [
    "Zesty, bright & vibrant",
    "Joyful, delicate & blooming",
    "Balmy, sweet & aromatic",
    "Warm, musky & woody",
    "Smokey, opulent & spicy",
  ];

  return (
    <div className="App bg-two">
      <div className="container">
        <div className="row d-flex h-100">
          <div className="col text-center align-self-center">
            <div className="container">
              <div className="row">
                <div className="col-12 f2 pb-5">
                  Which fragrance family
                  <br />
                  <span className="italic">do you prefer?</span>
                </div>
                <div className="d-flex justify-content-around">
                  {families.map((family, index) => (
                    <Family
                      key={index}
                      title={family.family}
                      name={family.name}
                      img={family.imgURLS}
                      description={descriptions[index]}
                      sex={sex}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whichfragance;
